import {
  LogoutInvalidToken,
  RefreshTokenReload,
  getCookie,
} from "../utils/utils";
import configData from "./configData.json";
// const configObj = config.get("configsGlobal");

const HTTP_METHODS = Object.freeze({
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE",
});

const MicroserviceFetchUtil = (() => {
  const dataCache = {};
  const defaultConfig = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  let controller = new AbortController();

  const stringToHex = (str) => {
    var arr = [];
    for (var i = 0; i < str.length; i++) {
      arr[i] = ("00" + str.charCodeAt(i).toString(16)).slice(-4);
    }
    return arr.join("");
  };

  const mainRequest = (url, method, customerToken) => {
    var myHeaders = new Headers();
    myHeaders.append("client_id", configData.mulesoftConfig.client_id);
    myHeaders.append("client_secret", configData.mulesoftConfig.client_secret);
    myHeaders.append("customerToken", customerToken.customerToken);
    myHeaders.append("countryCode", customerToken.countryCode);

    var requestOptions = {
      method,
      headers: myHeaders,
      redirect: "follow",
    };

    const finalURL = `${
      customerToken.countryCode === "id"
        ? configData.mulesoftConfig.microserviceEndpointId
        : configData.mulesoftConfig.microserviceEndpoint
    }${url}`;
    const req = new Request(finalURL, requestOptions);
    console.log("req", req);
    return new Promise((resolve, reject) => {
      fetch(req)
        .then((response) => {
          if (response) {
            if (
              response.ok &&
              response.status >= 200 &&
              response.status <= 299
            ) {
              return response.json();
            } else if (response.status >= 500) {
              return response.json();
            } else if (response.status === 404) {
              return response.json();
            } else {
              console.log(response);
              throw new Error(response);
            }
          }
        })
        .then(
          (data) => {
            if (
              data.statusCode >= 500 &&
              data.errors &&
              data.errors.errorPayload &&
              data.errors.errorPayload &&
              data.errors.errorPayload.error &&
              data.errors.errorPayload.error.message === "INVALID_ID_TOKEN"
            ) {
              const refreshToken = getCookie("refreshToken");
              if (refreshToken) {
                RefreshTokenReload(refreshToken);
              } else {
                LogoutInvalidToken();
              }
            }
            dataCache[stringToHex(req)] = data;
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  };

  const putRequest = (url, method, headerConfig, customerToken, body, app) => {
    const clientConfigss = {
      client_id: configData.mulesoftConfig.client_id,
      client_secret: configData.mulesoftConfig.client_secret,
      customerToken: customerToken.customerToken,
      countryCode: customerToken.countryCode,
    };
    const headers =
      app === "DeviceGeoCompliance"
        ? {
            //   ...defaultConfig,
            ...clientConfigss,
            //   "action":actionType,
            ...headerConfig,
          }
        : {
            ...defaultConfig,
            ...clientConfigss,
            //   "action":actionType,
            ...headerConfig,
          };
    const requestConfig = {
      method,
      headers,
      body,
      signal: controller.signal,
    };
    console.log(headers, requestConfig);
    if (method === HTTP_METHODS.POST || method === HTTP_METHODS.PUT) {
      requestConfig.body = JSON.stringify(body);
    }
    console.log("customet", customerToken);
    const finalURL = `${
      customerToken.countryCode === "id"
        ? configData.mulesoftConfig.microserviceEndpointId
        : configData.mulesoftConfig.microserviceEndpoint
    }${url} `;
    const req = new Request(finalURL, requestConfig);
    return new Promise((resolve, reject) => {
      fetch(req)
        .then((response) => {
          if (response) {
            if (
              response.ok &&
              response.status >= 200 &&
              response.status <= 299
            ) {
              return response.json();
            } else if (response.status === 404) {
              return response.json();
            } else if (response.status >= 500) {
              return response.json();
            } else {
              console.log(response);
              throw new Error(response);
            }
          }
        })
        .then(
          (data) => {
            if (
              data.statusCode >= 500 &&
              data.errors &&
              data.errors.errorPayload &&
              data.errors.errorPayload.error &&
              data.errors.errorPayload.error.message === "INVALID_ID_TOKEN"
            ) {
              const refreshToken = getCookie("refreshToken");
              if (refreshToken) {
                RefreshTokenReload(refreshToken);
              } else {
                LogoutInvalidToken();
              }
            }
            dataCache[stringToHex(req)] = data;
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  };

  const request = (url, method, headerConfig, body) => {
    const clientConfigs = {
      client_id: configData.mulesoftConfig.client_id,
      client_secret: configData.mulesoftConfig.client_secret,
      countryCode: headerConfig.countryCode,
    };
    const headers = {
      ...defaultConfig,
      ...clientConfigs,
      //   "action":actionType,
      ...headerConfig,
    };
    const requestConfig = {
      method,
      headers,
      body,
      signal: controller.signal,
    };
    console.log(headers, requestConfig);
    if (method === HTTP_METHODS.POST || method === HTTP_METHODS.PUT) {
      requestConfig.body = JSON.stringify(body);
    }
    const finalURL = `${
      headerConfig.countryCode === "id"
        ? configData.mulesoftConfig.microserviceEndpointId
        : configData.mulesoftConfig.microserviceEndpoint
    }${url} `;
    const req = new Request(finalURL, requestConfig);
    return new Promise((resolve, reject) => {
      fetch(req)
        .then((response) => {
          if (response) {
            if (
              response.ok &&
              response.status >= 200 &&
              response.status <= 299
            ) {
              return response.json();
            } else if (response.status === 404) {
              return response.json();
            } else if (response.status >= 500) {
              return response.json();
            } else {
              console.log(response);
              throw new Error(response);
            }
          }
        })
        .then(
          (data) => {
            if (
              data.statusCode >= 500 &&
              data.errors &&
              data.errors.errorPayload &&
              data.errors.errorPayload.error &&
              data.errors.errorPayload.error.message === "INVALID_ID_TOKEN"
            ) {
              const refreshToken = getCookie("refreshToken");
              if (refreshToken) {
                RefreshTokenReload(refreshToken);
              } else {
                LogoutInvalidToken();
              }
            }
            dataCache[stringToHex(req)] = data;
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  };

  const abort = () => controller.abort();

  return {
    post: (url, headerConfig = {}, body) => {
      return request(url, HTTP_METHODS.POST, headerConfig, body);
    },
    get: (url, customerToken) => {
      return mainRequest(url, HTTP_METHODS.GET, customerToken);
    },
    put: (url, customerToken, headerConfig = {}, body, app) => {
      console.log("customer", customerToken, app);
      return putRequest(
        url,
        HTTP_METHODS.PUT,
        headerConfig,
        customerToken,
        body,
        app
      );
    },
    delete: (url, headerConfig = {}) => {
      return request(url, HTTP_METHODS.DELETE, headerConfig);
    },
    abort: () => {
      abort();
      controller = new AbortController();
    },
  };
})();

export default MicroserviceFetchUtil;
