import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import closeIcon from "../../assets/images/popupclose.svg";
import styles from "./CustomToast.module.scss";

const CustomCrossIcon = () => {
  return (
    <div className={styles["close-button-container"]}>
      <CountdownCircleTimer
        isPlaying
        duration={5}
        size={50}
        strokeWidth={1.5}
        trailColor="#59B876"
        className="close-container"
        colors="#000000"
      >
        {({ remainingTime }) => (
          <div className="icon-container">
            <img src={closeIcon} alt="close" />
          </div>
        )}
      </CountdownCircleTimer>
    </div>
  );
};

export default CustomCrossIcon;
