import React, { useMemo, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import Routes from "../../routes";
import Header from "../Header/Header";
import LandingHeader from "../LandingHomePage/LandingHeader/LandingHeader";
import { getCookie } from "../../utils/utils";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../ContextStore/StoreContextProvider";
import MicroserviceFetchUtil from "../../common/microservice-fetch-util";
import BrandingLoader from "../Brandingloader/BrandingLoader";

export const ROUTES_WITHOUT_HEADER = ["/login", "/signup", "/forgotpassword"];
export const UCUBE_ROUTES = [
  "/",
  "/homepage",
  "/teamlevels",
  "/distributorteamlevels",
];

const Main = () => {
  const {
    userDetails,
    updateUserDetails,
    selectedCountryCode,
    updateCountryCode,
  } = useContext(StoreContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();
  const [ucubeAccess, setUcubeAccess] = useState(true);
  const [operationalAccess, setOperationalAccess] = useState(true);
  const [adminAccess, setAdminAccess] = useState(true);

  const { hasHeader } = useMemo(() => {
    console.log("pathname", pathname);
    return {
      hasHeader: !ROUTES_WITHOUT_HEADER.some(route =>
        route instanceof RegExp
          ? route.test(pathname)
          : pathname.search(route) !== -1
      ),
    };
  }, [pathname]);
  const { hasUcubeHeader } = useMemo(() => {
    return {
      hasUcubeHeader: UCUBE_ROUTES.includes(pathname),
    };
  }, [pathname]);

  useEffect(() => {
    if (getCookie("idToken") === null && pathname !== "/signup") {
      console.log("userDetailsPath..", pathname, getCookie("idToken"));
      history.push("/login");
    }
    if (getCookie("idToken") !== null) {
      console.log("userDetailsToken..", pathname, localStorage.getItem("email"),  localStorage.getItem("countryCode"), getCookie("idToken"));
      setIsLoading(true);
      const userEmailObj = { email: localStorage.getItem("email") };
      const userDetailsRes = MicroserviceFetchUtil.post(
        "fb/users/profile",
        {
          customerToken: getCookie("idToken"),
          countryCode: localStorage.getItem("countryCode"),
        },
        userEmailObj
      );
      userDetailsRes
        .then(response => {
          console.log("userDetailsResponse..", response);
          if (response.statusCode === 200 && response["response-data"]) {
            updateUserDetails(response["response-data"]);
            updateCountryCode(localStorage.getItem("countryCode"));
            setUcubeAccess(
              response["response-data"] &&
                response["response-data"].accessToUcube === "YES"
                ? true
                : false
            );
            setOperationalAccess(
              response["response-data"] &&
                response["response-data"].accessToOperationalDashboards ===
                  "YES"
                ? true
                : false
            );
            setAdminAccess(
              response["response-data"] &&
                response["response-data"].userType === "admin"
                ? true
                : false
            );
            setIsLoading(false);
          }
          setIsLoading(false);
        })
        .catch(error => {
          console.log("userDetailserror...",error);
          setIsLoading(false);
        });
    }
    console.log("userDetails..", userDetails);
  }, [selectedCountryCode]);

  console.log("hits", history, pathname);

  return (
    <>
      {isLoading ? (
        <BrandingLoader />
      ) : (
        <div
          style={{
            minHeight: "100Vh",
            maxHeight: "max-content",
            backgroundColor: window.innerWidth <= 768 ? "#FFFFF" : "#F3F7FD",
          }}
        >
          {hasHeader && hasUcubeHeader && <Header />}
          {hasHeader && !hasUcubeHeader && <LandingHeader />}
          <Routes
            adminAccess={adminAccess}
            ucubeAccess={ucubeAccess}
            operationalAccess={operationalAccess}
          />
        </div>
      )}
    </>
  );
};

export default Main;
