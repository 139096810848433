import React from "react";
import styles from "./brandingLoader.module.scss";
import Spinner from "../../assets/images/loader.gif";
const BrandingLoader = () => {
  return (
    <div className={styles.logo}>
      <img src={Spinner} className={styles.demo} alt="Loading..." />
    </div>
  );
};

export default BrandingLoader;
