import {
  UPDATE_USER_DETAILS,
  UPDATE_SELECTED_BSA,
  UPDATE_LEVEL,
  UPDATE_SELECTED_COUNTRYCODE,
  UPDATE_DT_ACCESS,
} from "../Actions/storeActionTypes";

const initialState = {
  userDetails: {},
  selectedBsa: "",
  level: [],
  selectedCountryCode: "",
  dtAccess: "",
};

const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.payload.userDetails,
      };
    }
    case UPDATE_SELECTED_BSA: {
      return {
        ...state,
        selectedBsa: action.payload.selectedBsa,
      };
    }
    case UPDATE_LEVEL: {
      return {
        ...state,
        level: action.payload && action.payload.level,
      };
    }
    case UPDATE_SELECTED_COUNTRYCODE: {
      return {
        ...state,
        selectedCountryCode: action.payload.selectedCountryCode,
      };
    }
    case UPDATE_DT_ACCESS: {
      return {
        ...state,
        dtAccess: action.payload.dtAccess,
      };
    }
    default:
      return state;
  }
};

export default storeReducer;
