import {
  UPDATE_USER_DETAILS,
  UPDATE_SELECTED_BSA,
  UPDATE_LEVEL,
  UPDATE_SELECTED_COUNTRYCODE,
  UPDATE_DT_ACCESS,
} from "./storeActionTypes";

export const updateUserDetailsAction = details => {
  return {
    type: UPDATE_USER_DETAILS,
    payload: {
      userDetails: details,
    },
  };
};

export const updateSelectedBsaAction = bsa => {
  return {
    type: UPDATE_SELECTED_BSA,
    payload: {
      selectedBsa: bsa,
    },
  };
};

export const updateLevelAction = level => {
  return {
    type: UPDATE_LEVEL,
    payload: {
      level: level,
    },
  };
};

export const updateSelectedCountryCodeAction = countryCode => {
  console.log("111111", countryCode);
  return {
    type: UPDATE_SELECTED_COUNTRYCODE,
    payload: {
      selectedCountryCode: countryCode,
    },
  };
};

export const updateDTAccessAction = dtAccess => {
  return {
    type: UPDATE_DT_ACCESS,
    payload: {
      dtAccess,
    },
  };
};
