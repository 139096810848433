import { MARKETS } from "../constants/config-constants";
// import env from "react-dotenv";
import config from "react-global-configuration";

const ConfigHelper = {
  loadConfig: async function () {
    // let COUNTRY_CODE =  env.COUNTRY_CODE;
    let COUNTRY_CODE = "VN";
    let configFileName;
    switch (COUNTRY_CODE) {
      case MARKETS.VIETNAM: {
        configFileName = "config.vn.json";
        break;
      }
      default: {
        configFileName = "config.vn.json";
      }
    }
    let filePath = "/market-configs/" + configFileName;
    const res = await fetch(filePath);
    return await res.json();
  },
  getCountryCode: function () {
    const configGlobal = config.get("configsGlobal");
    const countryCode = configGlobal.config.common.countryCode;
    return countryCode;
  },
};
export default ConfigHelper;
